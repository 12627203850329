import React, { useState, useEffect } from "react";
import { Layout } from "../Layout/Layout";
import { PostCard } from "../Layout/PostCard/PostCard";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactCarousel, { consts } from "react-elastic-carousel";

import postImage1 from "../../../assets/images/post-01.jpg";
import postImage2 from "../../../assets/images/post-02.jpeg";
import postImage3 from "../../../assets/images/post-03.jpg";
import avatarImg from "../../../assets/images/post-avatar.png";
import {
  AccuracyIcons,
  AlarmClock,
  Arrow,
  DUsers,
  PracticeChapter,
  Quizz,
  RankIcon,
  ScoreIcon,
  TestSeries,
} from "../../Core/Layout/Icon";
import { useDispatch, connect } from "react-redux";
import { getDashboard } from "./../../../redux/Dashboard/dashboard.actions";
import { createStructuredSelector } from "reselect";
import { selectDashboard } from "./../../../redux/Dashboard/dashboard.selectors";
import DashboardCard from "./DashboardComponents/DashboardCard";
import DashboardChart from "./DashboardComponents/DashboardChart";
import ExpiredSoonItem from "./DashboardComponents/ExpiredSoonItem";
import axios from "axios";
import { BASE_URL } from "./../../../config";
import RecentActivities from "./DashboardComponents/RecentActivities";
import PassActivities from "./DashboardComponents/PassActivities";
import Carousel from "../../Pages/Elements/Carousel";

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// 2nd slider
const settings1 = {
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Dashboard = ({ dashboard }) => {
  console.log(`neww`, dashboard);
  const [examTypes, setExamTypes] = useState([]);
  const [blogItems, setBlogItems] = useState([]);
  const [val, setVal] = useState(-1);
  const [student, setStudent] = useState();

  const dispatch = useDispatch();

  const myArrow = ({ type, onClick, isEdge }) => {
    const carlPointer = type === consts.PREV ? <Arrow /> : <Arrow />;
    const carlClass = type === consts.PREV ? "prev" : "next";
    return (
      <button
        className={`a-btn-arrow ${carlClass}`}
        onClick={onClick}
        disabled={isEdge}
      >
        {carlPointer}
      </button>
    );
  };

  const breakPoints = [
    { width: 2, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 850, itemsToShow: 3, itemsToScroll: 1 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 3 },
    { width: 1750, itemsToShow: 3 },
  ];

  useEffect(() => {
    dispatch(getDashboard());
    async function fetchTypes() {
      try {
        const {
          data: { data },
        } = await axios.get(`${BASE_URL}/misc/examType`);
        setExamTypes(data);
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchBlogs() {
      try {
        const {
          data: { data },
        } = await axios.get(`${BASE_URL}/homePage`);

        setBlogItems(data.home_page_blog_lists);
        setStudent(data.short_informations[0].studentLoveUs);
      } catch (error) {
        console.log(error);
      }
    }

    fetchTypes();
    fetchBlogs();
  }, []);

  return (
    <Layout>
      <div className="dashboard-card-items">
        <div className="dashboard-card-inner dashboard-card-inner-new">
          {dashboard &&
            Object.values({
              testAppeared: dashboard.testAppeared,
              score: dashboard.score,
              accuracy: dashboard.accuracy,
              rank: dashboard.rank,
            }).map((data, idx) => <DashboardCard data={data} idx={idx} />)}
        </div>
      </div>
      {dashboard && <DashboardChart dashboard={dashboard} />}

      {dashboard && dashboard.expired_soon_packages.length > 0 ? (
        <div className="filter-headear pt-30">
          <h3>
            Expiring Soon{" "}
            <span className="red">
              ({dashboard && dashboard.expired_soon_packages.length})
            </span>
          </h3>
          <div className="filter-group expiring-filter">
            <span>Filter by</span>
            <select
              defaultValue={"ALL"}
              onChange={(e) => setVal(Number(e.target.value))}
            >
              <option value={-1}>ALL</option>
              {dashboard &&
                dashboard.expired_soon_packages.map((x) =>
                  x.package_test_types.map((item) => (
                    <option value={item.test_type_id}>
                      {item.test_type_name}
                    </option>
                  ))
                )}
            </select>
          </div>
        </div>
      ) : null}
      <Slider {...settings}>
        {dashboard &&
          dashboard.expired_soon_packages
            .filter((x) => {
              if (val !== -1) {
                let arr = x.package_test_types.map((el) => el.test_type_id);

                return arr.includes(val);
              } else {
                return true;
              }
            })
            .map((el) => <ExpiredSoonItem el={el} />)}
      </Slider>

      <div className="row section-row">
        <RecentActivities />
        <PassActivities />
      </div>

      {/* <div className="recent-post">
        <div className="card">
          <div className="card-header filter-headear">
            <h3 className="">Recent Posts</h3>
            <div className="filter-group">
               <span>Filter by</span>
               <select>
                 <option>ALL</option>
                 <option>Current Affairs</option>
                 <option>Exam notification</option>
                 <option>Result</option>
               </select>
            </div>
          </div>
          <div className="recent-post-blog">
          
          {/* <Carousel heading={''}>
                    <ReactCarousel
                      itemsToShow={4}
                      itemsToScroll={1}
                      breakPoints={breakPoints}
                      renderArrow={myArrow}>
            {blogItems &&  blogItems.map((item) => (
              <PostCard key={item.id} item={item} />
            ))}    
            </ReactCarousel>
            </Carousel> */}
      {/* <Slider {...settings1}>
            {blogItems.map((item) => (
              <PostCard key={item.id} item={item} />
            ))} 
            </Slider>   
          </div>
          <span
            className="view">
              <a href="https://admisure.com/blog" target="_blank"> */}
      {/* View More
            <img
              src={require('../../../assets/images/view-more.svg')}
              alt=""
            /> */}
      {/* <img/>
            </a>
          </span>  
        </div> 
      </div> */}

      <div className="reg-wrapper">
        <div className="card">
          <ul className="reg-list">
            <li>
              <div className="content">
                <DUsers />
                <span className="label">{student}</span>
              </div>
              <p className="name">Registered Users</p>
            </li>
            <li>
              <div className="content">
                <TestSeries />
                <span className="label">
                  {dashboard && dashboard.totalTestPackage}
                </span>
              </div>
              <p className="name">Test Series</p>
            </li>
            <li>
              <div className="content">
                <PracticeChapter />
                <span className="label">
                  {dashboard && dashboard.totalPracticeSet}
                </span>
              </div>
              <p className="name">Practice Sets</p>
            </li>
            <li>
              <div className="content">
                <Quizz />
                <span className="label">
                  {dashboard && dashboard.totalQuiz}
                </span>
              </div>
              <p className="name">Quizzes</p>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  dashboard: selectDashboard,
});

export default connect(mapStateToProps)(Dashboard);
