export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';
export const FETCH_EXAM_PREFER_SUCCESS = 'FETCH_EXAM_PREFER_SUCCESS';
export const FETCH_EXAM_PREFER_FAILURE = 'FETCH_EXAM_PREFER_FAILURE';
export const UPDATE_EXAM_PREFER_SUCCESS = 'UPDATE_EXAM_PREFER_SUCCESS';
export const UPDATE_EXAM_PREFER_FAILURE = 'UPDATE_EXAM_PREFER_FAILURE';
export const FETCH_FOLLOWING_SUCCESS = 'FETCH_FOLLOWING_SUCCESS';
export const FETCH_FOLLOWING_FAILURE = 'FETCH_FOLLOWING_FAILURE';
export const UPDATE_FOLLOWING_LIST = 'UPDATE_FOLLOWING_LIST';
export const FILTER_FOLLOWINGS = 'FILTER_FOLLOWINGS';
export const WISHLIST_PACKAGES_SUCCESS = 'WISHLIST_PACKAGES_SUCCESS';
export const WISHLIST_PACKAGES_FAILURE = 'WISHLIST_PACKAGES_FAILURE';
export const WISHLIST_PRACTICE_SUCCESS = 'WISHLIST_PRACTICE_SUCCESS';
export const WISHLIST_PRACTICE_FAILURE = 'WISHLIST_PRACTICE_FAILURE';
export const WISHLIST_QUIZ_SUCCESS = 'WISHLIST_QUIZ_SUCCESS';
export const WISHLIST_QUIZ_FAILURE = 'WISHLIST_QUIZ_FAILURE';
export const UPDATE_WISHLIST_PACKAGE = 'UPDATE_WISHLIST_PACKAGE';
export const UPDATE_WISHLIST_PRACTICE = 'UPDATE_WISHLIST_PRACTICE';
export const UPDATE_WISHLIST_QUIZ = 'UPDATE_WISHLIST_QUIZ';
export const PURCHASE_HISTORY_SUCCESS = 'PURCHASE_HISTORY_SUCCESS';
export const PURCHASE_HISTORY_FAILURE = 'PURCHASE_HISTORY_FAILURE';
