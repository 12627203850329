import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setSingleQuestion } from "../../../../../redux/quiz/quiz.actions";
import {
  selectSolutionQuestions,
  selectSolutionSingleQuestion,
} from "../../../../../redux/quiz/quiz.selectors";
import SolutionEachQuestionNumber from "./../components/SolutionEachQuestionNumber";

const QuizSolutionBottomBar = ({
  modaToggleHandler,
  questions,
  singleQuestion,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const nextCount = (no, qus) => {
    // no === questions.length ? null : dispatch(setSingleQuestion(qus[no + 1]));
    if (no + 1 !== questions.length) {
      dispatch(setSingleQuestion(qus[no + 1]));
    }
  };
  const prevCount = (no, qus) => {
    // no === questions.length ? null : dispatch(setSingleQuestion(qus[no + 1]));
    if (no !== 0) {
      dispatch(setSingleQuestion(qus[no - 1]));
    }
  };
  return (
    <>
      <div className="quiz-footer">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            left: "40vw",
            top: "-50px",
          }}
        >
          {singleQuestion.questionSerialNo !== 0 ? (
            <button
              className="btn-warning-line"
              style={{ margin: "5px", padding: "6px 15px" }}
              onClick={() =>
                prevCount(singleQuestion.questionSerialNo, questions)
              }
            >
              Previous
            </button>
          ) : null}
          {singleQuestion.questionSerialNo + 1 !== questions.length ? (
            <button
              className="btn-warning-line"
              style={{ margin: "5px", padding: "6px 15px" }}
              onClick={() =>
                nextCount(singleQuestion.questionSerialNo, questions)
              }
            >
              Next
            </button>
          ) : null}
        </div>
        <div className="left-container">
          <div className="ans-type">
            <p className="ans">Answered</p>
            <p className="unans">Unanswered</p>
            <p className="notseen">Not Seen</p>
          </div>

          <div className="list">
            <SolutionEachQuestionNumber />
          </div>

          <span className="viewAll" onClick={() => modaToggleHandler()}>
            View All Questions
          </span>
        </div>

        <button
          className="btn-primary quiz-summary-btn"
          onClick={() => history.goBack()}
        >
          View Summary
        </button>
      </div>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  questions: selectSolutionQuestions,
  singleQuestion: selectSolutionSingleQuestion,
});
export default connect(mapStateToProps)(QuizSolutionBottomBar);
// export default QuizSolutionBottomBar;
