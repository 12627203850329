import React, { useEffect, useState } from "react";
import Layout from "../../Core/Layout/Layout";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import TestBannerBottom from "./TestBannerBottom";
import TestDetailsBanner from "./TestDetailsBanner";
import TestExamFeatures from "./TestExamFeatures";
import TestExamTab from "./TestExamTab";
import TestFreePackages from "./TestFreePackages";
import TestPass from "./TestPass";
import TestPractice from "./TestPractice";
import TestQuizzes from "./TestQuizzes";
import TestSeriesTest from "./TestSeriesTest";
import axios from "axios";
import { BASE_URL } from "./../../../config";
import TestFaq from "./TestFaq";
import TestBlogs from "./TestBlogs";
import PopularExams from "./PopularExams";
import { useLocation } from "react-router-dom";

export default function TestDetails(props) {
  const [data, setData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // let routeState;
    // if (location.state) {
    //   localStorage.setItem("routeState", JSON.stringify(location.state));
    //   routeState = location.state;
    // } else {
    //   routeState = localStorage.getItem("routeState");
    //   if (routeState) routeState = JSON.parse(routeState);
    // }

    // if (routeState) {

    (async function getDetails() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${BASE_URL}/examPage/exam-details/${props.match.params.id
            .split("-")
            .join(" ")}`
        );
        setData(data);
      } catch (error) {
        console.log(error);
      }
    })();
    // }
  }, []);

  // useEffect(() => {
  //   (async function getDetails() {
  //     try {
  //       const {
  //         data: { data },
  //       } = await axios.get(`${BASE_URL}/examPage/details/${match.params.id}`);

  //       setData(data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, []);

  const handleClick = (el) => {
    let demo = document.getElementById(`${el}`);
    demo.scrollIntoView({
      behavior: "smooth",
      block: "end",
      // inline: "nearest",
    });
    // setName(el.id);
    // if (el.productType === 1) {
    //   window.scrollTo({
    //     top: 700,
    //     behavior: "smooth",
    //   });
    // }
    // if (el.productType === 2) {
    //   window.scrollTo({
    //     top: 1200,
    //     behavior: "smooth",
    //   });
    // }
    // if (el.productType === 3) {
    //   window.scrollTo({
    //     top: 1700,
    //     behavior: "smooth",
    //   });
    // }
  };

  return (
    <Layout>
      {data === null ? (
        <div style={{ minHeight: "100vh" }}>
          <Loader
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            type="Oval"
            color="#FF7249"
            height={40}
            width={40}
            // timeout={3000} //3 secs
          />
        </div>
      ) : (
        <div className="a-wrapper practiceDetails test-details">
          <TestDetailsBanner data={data} />
          <TestBannerBottom data={data} handleClick={handleClick} />
          {data && data.examPageInfoCategory.length > 0 ? (
            <TestExamTab data={data} id={"Exam Details"} />
          ) : (
            <div className="a-nodata-Content">No data found</div>
          )}
          {data && data.examPageSection.length > 0 ? (
            <React.Fragment>
              {data.examPageSection.map((el) => {
                if (el.productType === 1) {
                  return <TestFreePackages data={el} id={el.sectionName} />;
                }
                // if (el.productType === "Test Series Package") {
                //   return <TestFreePackages data={el} />;
                // }
                // if (el.productType === "Test packages") {
                //   return <TestFreePackages data={el} />;
                // }

                if (el.productType === 2) {
                  return <TestPractice data={el} id={el.sectionName} />;
                }

                if (el.productType === 3) {
                  return <TestQuizzes data={el} id={el.sectionName} />;
                }
              })}
            </React.Fragment>
          ) : null}

          <TestPass data={data} />
          <TestExamFeatures />
          <TestFaq data={data} />
          <TestBlogs data={data} />
          <PopularExams data={data} />
        </div>
      )}
    </Layout>
  );
}
