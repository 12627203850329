import React, { useEffect } from "react";
import QuizSolutionQuestion from "./../components/QuizSolutionQuestion";
import QuizSolutionAnswers from "./../components/QuizSolutionAnswers";
import SolutionBox from "./../components/SolutionBox";
import { selectSolutionSingleQuestion } from "../../../../../redux/quiz/quiz.selectors";
import { connect, useDispatch } from "react-redux";
import { setSingleQuestion } from "../../../../../redux/quiz/quiz.actions";
import { createStructuredSelector } from "reselect";

const QuizSolutionQuestionWrapper = ({ questions, singleQuestion }) => {
  const dispatch = useDispatch();
  const nextCount = (no, qus) => {
    // no === questions.length ? null : dispatch(setSingleQuestion(qus[no + 1]));
    if (no + 1 !== questions.length) {
      dispatch(setSingleQuestion(qus[no + 1]));
    }
  };
  const prevCount = (no, qus) => {
    // no === questions.length ? null : dispatch(setSingleQuestion(qus[no + 1]));
    if (no !== 0) {
      dispatch(setSingleQuestion(qus[no - 1]));
    }
  };
  return (
    <div className="quiz-body-container"
    

    >
      <div className="a-container">
        <div className="quiz-header-group">
          <h2 className="quiz-heading">Solutions</h2>
        </div>
        <div className="quiz-card-wrapper" >
          <QuizSolutionQuestion questions={questions} />
          <QuizSolutionAnswers />
          <SolutionBox />
          
          {/* <div
            className=""
            style={{ display: "flex", justifyContent: "center", 
            
            }}
          >{singleQuestion.questionSerialNo!==0?
            <button
            className="btn-warning-line"
              style={{ margin: "5px" ,padding:"6px 15px"}}
              onClick={() =>
                prevCount(singleQuestion.questionSerialNo, questions)
              }
            >
              Previous
            </button>:null}
            <button
              className="btn-warning-line"
              style={{ margin: "5px" ,padding:"6px 15px"}}
              onClick={() =>
                nextCount(singleQuestion.questionSerialNo, questions)
              }
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

// export default QuizSolutionQuestionWrapper;

const mapStateToProps = createStructuredSelector({
  singleQuestion: selectSolutionSingleQuestion,
});

export default connect(mapStateToProps)(QuizSolutionQuestionWrapper);
