import React, { Fragment } from 'react';

const NoQuestion = () => {
  return (
    <Fragment>
      <h1>Sorry, No Question!</h1>
    </Fragment>
  );
};

export default NoQuestion;
