export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_SUCCESS';
export const TEST_PACKAGES_SUCCESS = 'TEST_PACKAGES_SUCCESS';
export const TEST_PACKAGES_FAILURE = 'TEST_PACKAGES_FAILURE';
export const TEST_SERIES_SUCCESS = 'TEST_SERIES_SUCCESS';
export const TEST_SERIES_FAILURE = 'TEST_SERIES_FAILURE';
export const PASS_COACHINGS_SUCCESS = 'PASS_COACHINGS_SUCCESS';
export const PASS_COACHINGS_FAILURE = 'PASS_COACHINGS_FAILURE';
export const PASS_CARDS_SUCCESS = 'PASS_CARDS_SUCCESS';
export const PASS_CARDS_FAILURE = 'PASS_CARDS_FAILURE';
export const ATTEMPTED_TEST_SUCCESS = 'ATTEMPTED_TEST_SUCCESS';
export const ATTEMPTED_TEST_FAILURE = 'ATTEMPTED_TEST_FAILURE';
export const PRACTICE_SET_SUCCESS = 'PRACTICE_SET_SUCCESS';
export const PRACTICE_SET_FAILURE = 'PRACTICE_SET_FAILURE';
export const PRACTICE_SET_NAME = 'PRACTICE_SET_NAME';
