import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import MainRouter from "./MainRouter";
import { Provider } from "react-redux";
import store from "./../store";
import { isLoggedIn } from "./../redux/actions/auth";
import { ToastProvider } from "react-toast-notifications";
import ReactMeta from "../utils/ReactMeta";
import { Helmet } from "react-helmet";

const Router = () => {
  useEffect(() => {
    store.dispatch(isLoggedIn());
    // console.log(document.head.meta);
    // let metas=document.getElementsByTagName('meta');
    // for(let i=0; i<metas.length;i++){
    //   console.log(metas[i].property,metas[i].content);
    // }

    // document.querySelector('meta[name="description"]').content="Join India's No.1 Preparation website to crack government exams with free unlimited online Test Series, Practice Sets, Quizzes for 100+ Government Exams. Enjoy the benefits of 500+ trusted coaching institutes for competitive exams including SSC, Railway, Banking, Defence and civil service examinations"
    // console.log(document.querySelector('meta[name="description"]').content)
  }, []);

  return (
    <>
      {/* <Helmet>
    <meta property="og:title" content="Admisure - India's No.1 Exam preparation platform with Unlimited Test Series, Mock Test, Quizzes for 100+ Government Exams" />
	<meta property="og:image" content="https://coaching.admisure.com/storage/home_page_sliders/1611748100a3ubI.png"/>
	<meta property="og:url" content="https://admisure.com/" />
	<meta property="og:description" content="Join India's No.1 Preparation website to crack government exams with free unlimited online Test Series, Practice Sets, Quizzes for 100+ Government Exams. Enjoy the benefits of 500+ trusted coaching institutes for competitive exams including SSC, Railway, Banking, Defence and civil service examinations" />
    </Helmet> */}
      {/* <HelmetMetaData title={"Admisure - India's No.1 Exam preparation platform with Unlimited Test Series, Mock Test, Quizzes for 100+ Government Exams"}/> */}
      <Provider store={store}>
        <BrowserRouter>
          <ToastProvider>
            <MainRouter />
          </ToastProvider>
        </BrowserRouter>
      </Provider>

      {/* <ReactMeta title={seo[0].seoTitle} description={seo[0].seoDesc} favicon={siteSettings[2].ImageUrl} /> */}
    </>
  );
};

export default Router;
