//For fetching quiz data
export const FETCH_QUIZ_QUESTION_SUCCESS = 'FETCH_QUIZ_QUESTION_SUCCESS';
export const FETCH_QUIZ_QUESTION_FAILURE = 'FETCH_QUIZ_QUESTION_FAILURE';
export const SINGLE_QUESTION_SUCCESS = 'SINGLE_QUESTION_SUCCESS';
export const SINGLE_QUESTION_FAILURE = 'SINGLE_QUESTION_FAILURE';
export const SAVE_QUESTION_STATE = 'SAVE_QUESTION_STATE';
export const ANSWER_SAVE_SUCCESS = 'ANSWER_SAVE_SUCCESS';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';
export const FETCH_SOLUTION_SUCCESS = 'FETCH_SOLUTION_SUCCESS';
export const FETCH_SOLUTION_FAILURE = 'FETCH_SOLUTION_FAILURE';
export const Q_LOADING_TRUE='Q_LOADING_TRUE'
