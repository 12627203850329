import React from 'react';
import { Layout } from '../Layout/Layout';

export const Performance = () => {
    return (
        <Layout>
            Performance
        </Layout>
    )
}
