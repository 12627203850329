export const FETCH_CHAPTERS_SUCCESS = 'FETCH_CHAPTERS_SUCCESS';
export const FETCH_CHAPTERS_FAILURE = 'FETCH_CHAPTERS_FAILURE';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_FAILURE = 'FETCH_QUESTIONS_FAILURE';
export const STORE_ANSWER_SUCCESS = 'STORE_ANSWER_SUCCESS';
export const STORE_ANSWER_FAILURE = 'STORE_ANSWER_FAILURE';
export const UPDATE_QUESTIONS_SUCCESS = 'UPDATE_QUESTIONS_SUCCESS';
export const UPDATE_QUESTIONS_FAILURE = 'UPDATE_QUESTIONS_FAILURE';
export const SAVE_ANSWER_SUCCESS = 'SAVE_ANSWER_SUCCESS';
export const SAVE_ANSWER_FAILURE = 'SAVE_ANSWER_FAILURE';
export const FETCH_PRACTICE_REPORT_SUCCESS = 'FETCH_PRACTICE_REPORT_SUCCESS';
export const FETCH_PRACTICE_REPORT_FAILURE = 'FETCH_PRACTICE_REPORT_FAILURE';
export const FILTER_QUESTION_SUCCESS = 'FILTER_QUESTION_SUCCESS';
export const FILTER_QUESTION_FAILURE = 'FILTER_QUESTION_FAILURE';
export const RESUME_SUCCESS = 'RESUME_SUCCESS';
export const PRACTICE_STATS_SUCCESS = 'PRACTICE_STATS_SUCCESS';
export const PRACTICE_STATS_FAILURE = 'PRACTICE_STATS_FAILURE';
export const LOADING_PROGRESS = 'LOADING_PROGRESS';
export const LOADING_FINISHED = 'LOADING_FINISHED';