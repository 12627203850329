export const QUESTIONLABEL = {
    HARD:'hard',
    EASY:'easy',
    MIDIUM:'medium'
}

export const QUESTIONATTEMPT = {
    INCORRECT:'Incorrect',
    CORRECT:'Correct',
    SCIPPED:'Skipped'
}