import React, { useEffect, useState } from "react";
import ReactCarousel, { consts } from "react-elastic-carousel";
import Carousel from "../Elements/Carousel";
import {
  Arrow,
  Heart,
  Location,
  Star,
  University,
} from "../../Core/Layout/Icon";
import { useHistory } from "react-router-dom";
import Slug from "../../../utils/slug";
import { toggleSignUp } from "../../../redux/actions/auth";
import { useDispatch } from "react-redux";

export default function TestQuizzes({ data }) {
  const [tagId, setTagId] = useState(null);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setTagId("All");
    setNewData(data.product_lists);
  }, [data]);

  // useEffect(() => {
  //   if (tagId) {
  //     setNewData(data.product_lists.filter((el) => el.tagId === tagId));
  //   }
  // }, [data, tagId]);

  const myArrow = ({ type, onClick, isEdge }) => {
    const carlPointer = type === consts.PREV ? <Arrow /> : <Arrow />;
    const carlClass = type === consts.PREV ? "prev" : "next";
    return (
      <button
        className={`a-btn-arrow ${carlClass}`}
        onClick={onClick}
        disabled={isEdge}
      >
        {carlPointer}
      </button>
    );
  };

  const breakPoints = [
    { width: 2, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 850, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  const history = useHistory();
  const dispatch = useDispatch();
  const startQuiz = (quizItem) => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      return dispatch(toggleSignUp(true));
    }
    window.location.href = `/c/${Slug(quizItem.coaching.coachingSlug)}/${Slug(
      quizItem.quizSlug
    )}/quiz`;
  };
  const viewResult = (quizItem) => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      return dispatch(toggleSignUp(true));
    }
    window.location.href = `/quizreport/${quizItem.id}/${quizItem.quizResultId}`;
  };

  let option = [
    ...new Set(
      data.product_lists.map((x) => {
        return {
          id: x.tag_data.id,
          name: x.tag_data.tagName,
        };
      })
    ),
  ];

  option = option.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => t.id === value.id && t.name === value.name)
  );
  const filters = (e) => {
    if (e.target.value === "All") {
      setTagId("All");
      setNewData(data.product_lists);
    } else {
      setTagId(Number(e.target.value));
      let x = [];
      x = data.product_lists.filter((i) => i.tagId === +e.target.value);
      // console.log(x);
      setNewData(x);
    }
  };

  return (
    <div className="test-quizzes" id={data.sectionName}>
      <div className="a-container">
        <div className="header-filter">
          <div className="left-content">
            <h2>{data.sectionName}</h2>
          </div>
          <div className="filter-group">
            <select
              name=""
              id=""
              className="bd-orange"
              value={tagId}
              onChange={filters}
            >
              {" "}
              <option value="All">All</option>
              {option.map((x) => (
                <option value={x.id}>{x.name}</option>
              ))}
            </select>
          </div>
        </div>

        <Carousel heading={""}>
          <ReactCarousel
            itemsToShow={4}
            itemsToScroll={1}
            breakPoints={breakPoints}
            renderArrow={myArrow}
          >
            {newData.map((item) => (
              <div className="a-carousel-item" key={item}>
                {/* <div className="a-wishlist">
                    <span>
                      <Heart />
                    </span>
                  </div> */}
                <span className="a-bggray">
                  <h4>{item.product_data.quizName}</h4>
                  <p>Attempted by {item.product_data.totalAttempt}</p>
                </span>
                <div className="a-listItem">
                  <div className="a-listTop">
                    <div className="a-itemHead">
                      <h4>{item.product_data.coaching.coachingName}</h4>
                      <div className="a-ratingandstars">
                        <div className="a-avatarProfile">
                          {item.product_data.coaching.logoUrl !== null ? (
                            <span
                              style={{
                                backgroundImage: `url(${item.product_data.coaching.logoUrl})`,
                              }}
                            ></span>
                          ) : (
                            <span
                              style={{
                                backgroundImage: `url('https://via.placeholder.com/40x40?text=${item.product_data.coaching.coachingName}')`,
                              }}
                            ></span>
                          )}
                        </div>
                        {!item.product_data.rating ? (
                          <b>
                            <span>
                              <Star />
                            </span>{" "}
                            -
                          </b>
                        ) : (
                          <b>
                            <span>
                              <Star />
                            </span>
                            {/* {item.rating.toFixed(1)} */}
                            {(item.product_data.rating &&
                              item.product_data.ratingCount.toFixed(1) > 2 &&
                              item.product_data.rating.toFixed(1)) ||
                              `-`}
                          </b>
                        )}

                        <b>
                          (
                          {item.product_data.ratingCount.toFixed(1) > 2
                            ? `${item.product_data.ratingCount} Ratings`
                            : `Not Rated`}
                          )
                        </b>
                      </div>
                    </div>
                    <p className="a-location">
                      <span>
                        <Location />
                      </span>
                      {item.product_data.coaching.city.city},{" "}
                      {item.product_data.coaching.state.name}
                    </p>
                    <p className="a-typeExam">
                      {!item.product_data.examType
                        ? ""
                        : item.product_data.examType.examType}
                      {"  "}
                    </p>
                    <ul className="a-optionDetails">
                      <li>Questions: {item.product_data.quizQuestionCount}</li>
                      {/* <li>
                        NO of Subject: {item.product_data.quizSubjectCount}
                      </li> */}
                      <li>Max. Marks: {item.product_data.totalMarks}</li>
                      <li>Time: {item.product_data.duration} Minutes</li>
                      <li>
                        {!item.product_data.language
                          ? ""
                          : item.product_data.language.languageName}
                        {"  "}
                      </li>
                    </ul>
                    {item.product_data.quizResultCount === 0 ? (
                      <div
                        className="a-detailsBtn"
                        onClick={() => startQuiz(item.product_data)}
                      >
                        <span>Start Quiz</span>
                      </div>
                    ) : null}
                    {item.product_data.quizResultCount > 0 ? (
                      item.product_data.quizCompleted === 1 ? (
                        <div
                          className="a-detailsBtn"
                          onClick={() => viewResult(item.product_data)}
                        >
                          <span>View Result</span>
                        </div>
                      ) : null
                    ) : null}
                    {item.product_data.quizResultCount > 0 ? (
                      item.product_data.quizCompleted !== 1 ? (
                        <div
                          className="a-detailsBtn"
                          onClick={() => startQuiz(item.product_data)}
                        >
                          <span>Resume Quiz</span>
                        </div>
                      ) : null
                    ) : null}
                    {/* {item.product_data.isPublished === 1 &&
                    item.product_data.status === 5 ? (
                      <>
                        <div
                          className="a-detailsBtn"
                          onClick={() => startQuiz(item.product_data)}
                        >
                          <span>Start Quiz</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="a-detailsBtn">
                          <button
                            className="disabled btn-primary radius btn-block"
                            type="button"
                            disabled
                          >
                            UNAVAILABLE
                          </button>
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            ))}
          </ReactCarousel>
        </Carousel>
      </div>
    </div>
  );
}
